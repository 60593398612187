const snackbarPlugin = {
    install: (Vue, { store }) => {

      if (!store) {
        throw new Error('Please provide vuex store.')
      }

      Vue.prototype.$snackbar = {
        showMessage: function ({
          content = '',
          color = '',
          pause = 0,
        }) {
          store.commit('application/SHOW_MESSAGE',
            { content, color },
            { root: true }
          )
          setTimeout(()=> {
            console.log('in timout for: ', pause)
          }, pause)
        }
      }

    },
  }

  export default snackbarPlugin